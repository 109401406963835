<template>
  <div class="for-providers-region-picker">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div class="for-providers-region-tabs">
            <button @click="regionTabSelected_clickHandler('us')"  class="for-providers-region-tab tab-right" :class="selectedRegion=='us' ? 'active':''">
              Care in the US
            </button>
            <button @click="regionTabSelected_clickHandler('uk')" class="for-providers-region-tab tab-left" :class="selectedRegion=='uk' ? 'active':''">
              Care in the UK
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="for-providers-region-tab-content relative">
      <shape placement="top" shape="curve" type="8" color="white" classes="shape-blur"></shape>
      <div v-if="selectedRegion == 'uk'" class="for-providers-region-tab-content-uk">
        <div class="container">
          <div class="row d-flex align-items-center pb-8">
            <div class="col-12 col-lg-5">
              <h2 class="">Supporting Respiratory Care in the UK</h2>
              <p>
                Since 2017, NuvoAir has partnered with NHS Trusts to support respiratory clinicians and patients in the fields of asthma, COPD, cystic fibrosis, primary ciliary dyskinesia (PCD) and lung transplant.
              </p>
            </div>
            <div class="col-12 col-lg-7">
              <div class="image-container" style="background-image:  url('/img/providers-uk.jpg');"></div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row pt-8 pb-8 justify-content-center">
            <div class="col-12">
              <h2 class="mt-8 text-center">The NuvoAir Asthma Service</h2>
              <p class="text-center mb-9">
                <a target="_blank" href="https://www.nuvoair.com/blog/nuvoair-launches-remote-asthma-service-to-support-nhs-with-faster-diagnosis-and-treatment">Launched in early 2022</a>, the NuvoAir Asthma Service utilizes our team of respiratory physiologists and proprietary digital technology to facilitate faster and more informed clinician decision-making across the continuum of care -  from initial assessment of people with uncontrolled symptoms to ongoing monitoring of those with severe asthma on biologic therapies.
              </p>
              <div class="row mt-8">
                <div class="col-12 col-md-6 col-lg-4 providers-asthma-row">
                  <div class="providers-asthma-image-wrapper">
                    <img style="position: relative; top: -25px;" class="providers-asthma-img" src="~/assets/img/forProviders/providers-uk-1.png" alt="">
                  </div>
                  <div class="providers-asthma-description">
                    <span>1</span>
                    <p>Award-winning technology is shipped directly to patients</p>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 providers-asthma-row">
                  <div class="providers-asthma-image-wrapper">
                    <img class="providers-asthma-img" src="~/assets/img/forProviders/providers-uk-2.png" alt="">
                  </div>
                  <div class="providers-asthma-description">
                    <span>2</span>
                    <p>NuvoAir’s team supports technical onboarding and data collection</p>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 providers-asthma-row">
                  <div class="providers-asthma-image-wrapper">
                    <img class="providers-asthma-img" src="~/assets/img/forProviders/providers-uk-3.png" alt="">
                  </div>
                  <div class="providers-asthma-description">
                    <span>3</span>
                    <p>Respiratory physiologists provide spirometry training and coaching</p>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 providers-asthma-row">
                  <div class="providers-asthma-image-wrapper">
                    <img class="providers-asthma-img" src="~/assets/img/forProviders/providers-uk-4.png" alt="">
                  </div>
                  <div class="providers-asthma-description">
                    <span>4</span>
                    <p>Monitoring of pMDI use, inhaler technique, lung function variability, symptom scores, and patient reported outcomes</p>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 providers-asthma-row">
                  <div class="providers-asthma-image-wrapper">
                    <img style="position: relative; top: -30px;" class="providers-asthma-img" src="~/assets/img/forProviders/providers-uk-5.png" alt="">
                  </div>
                  <div class="providers-asthma-description">
                    <span>5</span>
                    <p>Reminders support engagement </p>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 providers-asthma-row">
                  <div class="providers-asthma-image-wrapper">
                    <img class="providers-asthma-img" src="~/assets/img/forProviders/providers-uk-6.png" alt="">
                  </div>
                  <div class="providers-asthma-description">
                    <span>6</span>
                    <p>Regular huddles with your clinical team and real-time access to patient data and insights</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-section-gradient-bg">
          <div class="container">
            <div class="row d-flex align-items-center pt-8 pb-8">
              <div class="col-12 col-lg-6 align-content-center">
                <h2 class="">Learn More About NuvoAir’s Asthma Service</h2>
                <p>
                  Macbeth was one of the 1.3 million people suffering from uncontrolled asthma in the UK. Hear how NuvoAir’s Asthma Service has impacted Macbeth’s life and learn how the service is supporting respiratory clinicians to diagnose, manage and treat patients.
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <div class="video-container">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/yct3u5v68Q4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-care-blog-posts mt-8 mb-8">
          <div class="container">
            <div class="col-lg-10 offset-lg-1 col-12 text-center">
              <h2>Learn More About Our Work in the UK</h2>
              <p class="main-page-p mb-10">
                Follow our blog posts for news and insights from clinicians and patients across the country
              </p>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12"
                   v-for="blogPost in blogPosts"
                   v-bind:key="blogPost.id">
                <blog-card :blogPost="blogPost" />
              </div>
              <div v-if="$apollo.loading"
                   class="row align-items-center justify-content-center no-gutters">
                <div class="col-12 col-md-5 col-lg-4 py-8 py-md-11">
                  <h2 class="display-3 font-weight-bold text-center">
                    Loading...
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeEnterpriseUk />
        <home-proven-results />
        <Partners />
        <JoinNuvoAirSection />
      </div>
      <div v-if="selectedRegion == 'us'" class="for-providers-region-tab-content-uk">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-12 col-lg-5">
              <h2 class="">Supporting Members with Complex Needs</h2>
              <p>
                NuvoAir is re-imagining the patient experience for people living with complex needs. Members diagnosed with COPD often live with comorbidities such as CHF, which makes care challenging. Leading health plans, ACOs and providers rely on NuvoAir to improve outcomes, lower cost of care and drive better quality of life through our virtual-first care model.
              </p>
            </div>
            <div class="col-12 col-lg-7">
              <div class="image-container" style="background-image:  url('/img/providers-us.jpg');"></div>
            </div>
          </div>
        </div>
        <how-it-works />
        <home-enterprise />
        <home-proven-results-us />
        <HealthPlansForm />
      </div>
    </div>
  </div>
</template>
<script>
import './forProvidersRegionPicker.scss';
import Shape from "~/components/ui-kit/shape";
import blogPostsQuery from "~/apollo/queries/blog/careUKBlogPosts.gql";
import BlogCard from "~/components/blog/BlogCard";
import HowItWorks from '../home/howItWorks.vue';
import HomeEnterprise from '../home/homeEnterprise.vue';
import HomeProvenResults from '../home/homeProvenResults.vue';
import HomeProvenResultsUs from '../home/homeProvenResultsUs.vue';
import JoinNuvoAirSection from '../joinNuvoAirSection.vue';
import Partners from '../partners.vue';
import HomeEnterpriseUk from '../home/homeEnterpriseUk.vue';
import HealthPlansForm from '../for-health-plans/healthPlansForm.vue';
export default {
  name: 'forProvidersRegionPicker',
  components: { BlogCard, Shape, HowItWorks, HomeEnterprise, HomeProvenResults, HomeProvenResultsUs, JoinNuvoAirSection, Partners, HomeEnterpriseUk, HealthPlansForm },
  props: {},
  data() {
    return {
      selectedRegion: 'us',
      blogPosts: []
    };
  },
  mounted() {
    console.log('forProvidersRegionSpecifics mounted');
  },
  apollo: {
    blogPosts: {
      prefetch: true,
      query: blogPostsQuery
    }
  },
  methods: {
    regionTabSelected_clickHandler(region) {
      this.selectedRegion = region
    }
  }
};
</script>
