<template>
  <div class="health-plans-form pt-9 pb-9" id="healthPlansForm">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="row">
            <div class="col-lg-5 col-md-6 col-12">
              <h2>Learn more about how NuvoAir can support your cardiopulmonary care needs</h2>
              <p class="main-page-p">Our team will reach out to share more information about our virtual-first specialty care model.</p>
            </div>
            <div class="col-lg-7 col-md-6 col-12">
              <div class="health-plans-form-card card lift shadow">
                <organization-signup-form category="health_plans" submit-button-class="btn-hp" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationSignupForm from '../forms/organizationSignupForm';
import './healthPlansForm.scss';

export default {
  components: { OrganizationSignupForm }
};
</script>
