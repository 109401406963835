<template>
  <div class="home-reimagining-care pt-9">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-10 offset-lg-1">
          <h2 class="text-center">Reimagining heart and lung care</h2>
        </div>
      </div>
      <div class="adherence d-flex row-desktop-column-mobile mt-10 mb-10 align-items-center home-reimagining-care-section">
        <div class="col col-md-6 px-4">
          <h3>Personalized programs</h3>
          <p class="main-page-p">
            We tailor clinical programs to members' unique needs, based on their motivations and risk factors.
          </p>
          <div class="row home-reimagining-care-checks">
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Smoking cessation</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Pulmonary and cardiac rehabilitation</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Fitness, diet, and nutrition</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Treatment plan review</span>
            </div>
          </div>
        </div>

        <div class="col col-md-6 px-4">
          <img class="img-fluid home-reimagining-care-img" alt="A woman using getting an SPO2 reading" src="~/assets/img/home/home-reimagining-care-1.png" />
        </div>
      </div>
      <div class="d-flex row-desktop-column-mobile mb-10 align-items-center home-reimagining-care-section">
        <div class="col col-md-6 px-4">
          <img class="img-fluid home-reimagining-care-img" alt="A man using a NuvoAir AirNext spirometer" src="~/assets/img/home/home-reimagining-care-2.png" />
        </div>
        <div class="col col-md-6 align-items-center px-4">
          <h3>Clinically relevant data</h3>
          <p class="main-page-p">
            Our devices continuously and remotely collect biomarkers to empower the best clinical decisions.
          </p>
          <div class="row home-reimagining-care-checks">
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Oxygen saturation</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Activity</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Spirometry</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Weight</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Medication Adherence</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Blood pressure</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Cough</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row mb-5">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Heart rate</span>
            </div>
          </div>
        </div>
      </div>
      <div class="adherence d-flex row-desktop-column-mobile mb-10 align-items-center home-reimagining-care-section">
        <div class="col col-md-6 px-4">
          <h3>Virtual clinic</h3>
          <p class="main-page-p">
            Our interdisciplinary care team conducts timely clinical interventions whenever patients need help or signals in the data indicate concern.
          </p>
          <div class="row home-reimagining-care-checks">
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Cardiologists and Pulmonologists</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Nurses</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Respiratory therapists</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Health coaches</span>
            </div>
          </div>
        </div>

        <div class="col col-md-6 px-4">
          <img class="img-fluid  home-reimagining-care-img" alt="A woman using getting an SPO2 reading" src="~/assets/img/home/home-reimagining-care-3.png" />
        </div>
      </div>
      <div class="d-flex row-desktop-column-mobile mb-10 align-items-center home-reimagining-care-section">
        <div class="col col-md-6 px-4">
          <img class="img-fluid home-reimagining-care-img" alt="A man using a NuvoAir AirNext spirometer" src="~/assets/img/home/home-reimagining-care-4.png" />
        </div>
        <div class="col col-md-6 align-items-center px-4">
          <h3>Exacerbation prevention</h3>
          <p class="main-page-p">
            Our analytics engine drives the next best action in our clinical model to predict and prevent exacerbations.
          </p>
          <div class="row home-reimagining-care-checks">
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Reduced healthcare costs</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Better clinical outcomes</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Improved patient experience</span>
            </div>
            <div class="monitoring-container col-6 d-flex flex-row">
              <img class="checkmark" alt="" src="~/assets/img/forProviders/for-providers-check.png" />
              <span class="text-primary">Enhanced Hedis/Stars/CAHPS measures</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.adherence {
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.home-reimagining-care {
  background: linear-gradient(180deg, #F9FBFD 0%, rgba(249, 251, 253, 0) 100%);
  padding: 64px 0;
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #161B2D;
    margin-bottom: 16px;
  }
}

.checkmark {
  margin-top: 3px;
  width: 21px;
  height: 21px;
  margin-right: 16px;
}

.monitoring-container {
  margin: 8px 0;
}

.home-reimagining-care-img {
  width: 100%;
  height: auto;
  max-width: 305px;
  margin: auto;
  display: block;
}
@media screen and (max-width: 767px) {
  .home-reimagining-care-img {
    margin-bottom: 30px;
  }
  .monitoring-container {
    span {
      font-size: 14px;
    }
    &:nth-child(even) {
      span {
        padding: 0px;
      }
    }
  }
  .home-reimagining-care-checks {
    .monitoring-container:nth-child(even) {
      padding: 0px;
    }
  }
}
</style>
