<template>
  <div class="home-partnering">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-10 offset-lg-1">
          <h2 class="mb-9 mt-9 text-center">Partnering across the spectrum of care</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 mb-5">
          <div class="partnering-card">
            <div class="img-wrapper mb-6" style="background-image: url('/img/home/home-partnering-1.jpg')"></div>
            <h3 class="mb-5">Patients</h3>
            <p class="mb-5">
              Empowering patients to improve outcomes and reduce flare-ups
            </p>
            <nuxt-link class="btn btn-sm btn-primary btn-pill lift mb-6" to="/for-patients">Learn More</nuxt-link>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-5">
          <div class="partnering-card">
            <div class="img-wrapper mb-6" style="background-image: url('/img/home/home-partnering-2.jpg')"></div>
            <h3 class="mb-5">Providers</h3>
            <p class="mb-5">
              Enhancing practices through proactive care offered at-scale
            </p>
            <nuxt-link class="btn btn-sm btn-primary btn-pill lift mb-6" to="/for-providers">Learn More</nuxt-link>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-5">
          <div class="partnering-card">
            <div class="img-wrapper mb-6" style="background-image: url('/img/home/home-partnering-3.jpg')"></div>
            <h3 class="mb-5">Payors</h3>
            <p class="mb-5">
              Improving outcomes for patients while reducing costs for health plans
            </p>
            <nuxt-link class="btn btn-sm btn-primary btn-pill lift mb-6" to="/for-health-plans">Learn More</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
  .home-partnering {
    background: linear-gradient(180deg, #F9FBFD 0%, rgba(249, 251, 253, 0) 100%);
    .partnering-card {
      max-width: 100%;
      margin: auto;
      display: block;
      .img-wrapper {
        height: 250px;
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }
  @media (max-width: 767px) {
    .home-partnering {
      .partnering-card {
        max-width: 100%;
        .img-wrapper {
          width: 100%;
        }
      }
    }
  }
</style>
