<template>
  <div class="how-it-works container">
    <div class="row">
      <div class="col-10 offset-1 text-center">
        <h2>How it Works</h2>
        <p class="main-page-p">
          NuvoAir embraces the complexities of COPD care by delivering continuous proactive, personalized care at scale through our virtual-first care (V1C) solution that blends connected devices, disruptive technology and human-led care to support the diagnosis, management and treatment of members living with comorbidities.
        </p>
      </div>
    </div>
    <div class="how-it-works-cards">
      <div v-for="(card, ix) in cards" :key="ix" :class="cardStyle(ix)">
        <div class="img-container">
          <img :src="card.image_src" alt="" class="card-image" />
        </div>
        <div class="description-container">
          <h4 class="how-it-works-title">{{ card.title }}</h4>
          <div class="how-it-works-description">{{ card.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./howItWorks.scss";
import classnames from "classnames";
import GlobalMixin from "~/mixins/GlobalMixin";

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      cards: [
        {
          title: "Biomarkers",
          description:"Connected and easy to use Bluetooth, cell- enabled devices allow for  remote collection and continuous monitoring of key health data, even in the most rural areas",
          image_src: require(`~/assets/img/home/how-it-works-1.jpg`),
        },
        {
          title: "Data Insights",
          description: "Our analytics engine creates insights from a wide range of data sets to inform our Next Best Action Platform for early intervention",
          image_src: require(`~/assets/img/home/how-it-works-2.jpg`),
        },
        {
          title: "Engagement",
          description: "Our digital care platform leverages data to enable smart automation and personalized care at scale, while our interdisciplinary care team monitors and intervenes when needed",
          image_src: require(`~/assets/img/home/how-it-works-3.jpg`),
        },
        {
          title: "Virtual clinic",
          description:
            "Our virtual interdisciplinary care team including care coordinators, respiratory therapists, nurses, pulmonologists, and cardiologists support members on-demand and enable timely clinical response and follow-up",
          image_src: require(`~/assets/img/home/how-it-works-4.jpg`),
        },
      ],
    };
  },
  methods: {
    cardStyle(ix) {
      const isEven = ix % 2 === 0;

      return classnames("how-it-works-card", isEven ? "left-card" : "right-card");
    },
    setCardTitle(ix, title) {
      console.log("setCardTitle", ix, title);
      this.cards[ix].title = title;
    },
    setCardDescription(ix, description) {
      console.log("setCardTitle", ix, description);
      this.cards[ix].description = description;
    },
    checkVisitorLocation() {
      // if (this.visitorLocation && this.visitorLocation.countryCode === 'GB') {
      //   this.setCardDescription(2,'We support your clinical capacity and enable you to focus on patients most in need, allowing you to increase your patient throughput and care for more people.')
      // }
      // else {
      //   this.setCardDescription(2,'Our care team follows the patients where they are, providing continuous care and longitudinal insights, in-between visits')
      // }
    }
  },
  mounted() {
    this.checkVisitorLocation();
  },
  computed: {
    visitorLocation() {
      return this.$store.state.global.visitorLocation;
    }
  },
  watch: {
    "$store.state.global"(newVal) {
      console.log(newVal);
      this.visitorLocation = newVal.visitorLocation;
    },
    "visitorLocation"() {
      console.log("this.visitorLocation", this.visitorLocation);
      this.checkVisitorLocation()
    }
  }
};
</script>
