<template>
  <div class="home page-content">
    <main-page-jumbo :title="title"
                     :desc="description"
                     action="health-plans-form"
                     actionText="Talk to our team"
                     :imageUrl="imageUrl" />
<!--    <for-providers-region-picker />-->
    <home-managing-patients />
    <home-embracing-complexity />
    <home-reimagining-care />
    <home-proven-results-us />
    <home-partnering />
    <home-availability-map :title="availabilityTitle" :imgUrl="availabilityMap"/>
    <health-plans-form />
<!--    <home-stories-from-heroes :blog-posts-featured='blogPostsFeatured' />-->
<!--    <awards-and-recognition :awards="awards" />-->
    <shape placement="bottom" shape="curve" type="1" color="gray-900" />
  </div>
</template>

<script>
import AwardsAndRecognition from "@/components/awardsAndRecognition.vue";
import HomeEnterprise from "@/components/home/homeEnterprise.vue";
import HomeProvenResults from "@/components/home/homeProvenResults.vue";
import joinNuvoAirSection from "@/components/joinNuvoAirSection";
import ForProvidersRegionPicker from "~/components/for-providers/forProvidersRegionPicker";
import MainPageJumbo from "@/components/mainPageJumbo.vue";
import Partners from "@/components/partners.vue";
import Shape from "@/components/ui-kit/shape.vue";
import homePageSettingsQuery from "@/apollo/queries/homePageSettings.gql";
import partnersQuery from "@/apollo/queries/partners.gql";
import awardsQuery from "@/apollo/queries/awards.gql";
import GlobalMixin from "@/mixins/GlobalMixin";
import { loadGraphQLHelper } from "@/util/nuxtUtil";
import HomeStoriesFromHeroes from '~/components/home/homeStoriesFromHeroes';
import blogPostsFeaturedQuery from '~/apollo/queries/blog/blogPostsFeatured.gql';
import HowItWorks from '~/components/home/howItWorks';
import imageSplash from '~/assets/img/jumbo/home-splash.png';
import './index.scss';
import HealthPlansForm from "~/components/for-health-plans/healthPlansForm.vue";
import HomeReimaginingCare from "~/components/for-providers/homeReimaginingCare.vue";
import HomeManagingPatients from "~/components/home/homeManagingPatients.vue";
import HomeEmbracingComplexity from "~/components/home/homeEmbracingComplexity.vue";
import HomePartnering from "~/components/home/homePartnering.vue";
import HomeAvailabilityMap from "~/components/home/homeAvailabilityMap.vue";
import HomeProvenResultsUs from "~/components/home/homeProvenResultsUs.vue";

export default {
  mixins: [GlobalMixin],
  components: {
    HomeProvenResultsUs,
    HomeAvailabilityMap,
    HomePartnering,
    HomeEmbracingComplexity,
    HomeManagingPatients,
    HomeReimaginingCare,
    HomeStoriesFromHeroes,
    joinNuvoAirSection,
    AwardsAndRecognition,
    MainPageJumbo,
    HomeEnterprise,
    HomeProvenResults,
    Partners,
    Shape,
    HowItWorks,
    ForProvidersRegionPicker,
    HealthPlansForm
},
  data() {
    return {
      homePageSettings: undefined,
      partners: [],
      awards: [],
      blogPostsFeatured: undefined,
      imageSplash
    };
  },
  async asyncData({ app, req }) {
    const partners = (await loadGraphQLHelper({ app, query: partnersQuery })).partners;
    const awards = (await loadGraphQLHelper({ app, query: awardsQuery })).awards;
    const homePageSettings = (await loadGraphQLHelper({ app, query: homePageSettingsQuery })).homePageSetting;
    const blogPostsFeatured = (await loadGraphQLHelper({ app, query: blogPostsFeaturedQuery })).blogPostsFeatureds;

    return {
      host: process.server
        ? "https://" + req.headers.host
        : window.location.origin,
      homePageSettings,
      partners,
      awards,
      blogPostsFeatured
    };
  },
  computed: {
    title() {
      return this.homePageSettings ? this.homePageSettings.title : "";
    },
    description() {
      return this.homePageSettings ? this.homePageSettings.description : "";
    },
    availabilityTitle() {
      return this.homePageSettings ? this.homePageSettings.availability_title : "";
    },
    availabilityMap() {
      return this.homePageSettings ? this.homePageSettings.availability_map.url : "";
    },
    imageUrl() {
      return this.homePageSettings.header_image ? this.homePageSettings.header_image.url : '';
    },
  },
  head() {
    // First pass this will be empty until asyncData has completed.
    return !this.homePageSettings
      ? {}
      : this.headHelper({
          title: this.homePageSettings.seo_title,
          description: this.homePageSettings.seo_description,
          image: this.homePageSettings.seo_image,
          tags: this.homePageSettings.seo_tags
        });
  }
};
</script>
