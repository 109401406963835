<template>
  <div class="home-enterprise">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2>Proactive, Personalized Care at Scale</h2>
          <p class="main-page-p">
            NuvoAir brings confidence, better health outcomes and quality of life to members and improved performance ratings (HEDIS, Star and CAHPS) and cost savings for risk-bearing partners.
          </p>
          <br />
          <div class="row align-start">
            <div class="col-lg-6">
              <div class="enterprise-bullet">
                <span class="badge badge-rounded-circle bg-primary-soft">
                  <i class="fe fe-check"></i>
                </span>
                <p class="enterprise-bullet-text">
                  A personalized engagement experience shapes behavior change in ways that work best for each member
                </p>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="enterprise-bullet">
                <span class="badge badge-rounded-circle bg-primary-soft">
                  <i class="fe fe-check"></i>
                </span>
                <p class="enterprise-bullet-text">
                  Members are stratified according to leading care guidelines to provide the appropriate level of care
                </p>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="enterprise-bullet">
                <span class="badge badge-rounded-circle bg-primary-soft">
                  <i class="fe fe-check"></i>
                </span>
                <p class="enterprise-bullet-text">
                  Data is monitored for insights that enable better diagnosis and next best action for care
                </p>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="enterprise-bullet">
                <span class="badge badge-rounded-circle bg-primary-soft">
                  <i class="fe fe-check"></i>
                </span>
                <p class="enterprise-bullet-text">
                  Real-time insights enable our care team to triage and treat members through our virtual clinic
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <img
            class="jarallax img-fluid home-enterprise-image"
            data-jarallax
            data-speed=".8"
            src="../../assets/img/home/home-enterprise-img.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalMixin from "~/mixins/GlobalMixin";
export default {
  mixins: [GlobalMixin],
  computed: {
    visitorLocation() {
      console.log(this.$store.state.global.visitorLocation);
      return this.$store.state.global.visitorLocation;
    }
  },
  watch: {
    "$store.state.global"(newVal, oldVal) {
      console.log(newVal);
      this.visitorLocation = newVal.visitorLocation;
    }
  }
};
</script>
<style lang="scss" scoped>
.home-enterprise {
  padding: 64px 16px 16px 16px;

  .row {
    align-items: center;
  }
}

.align-start {
  align-items: start !important;
}

.custom-enterprise-badge {
  background-color: rgba(0, 115, 230, 0.1);
  color: #0073e6;
  padding: 6px 24px;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 16px;
}

.enterprise-bullet-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 161.54%;
  color: #0073e6;
  padding-left: 8px;
}

.enterprise-bullet {
  display: flex;
  span {
    display: inline-flex;
    position: relative;
    top: 4px;
  }
  p {
    display: inline-flex;
  }
}
@media (max-width: 992px) {
  .home-enterprise-image {
    max-width: 512px;
    height: auto;
    display: block;
    margin: 50px auto;
  }
}
@media (max-width: 767px) {
  .home-enterprise {
    padding: 32px 0;
  }
  .home-enterprise-image {
    max-width: 100%;
    width: 375px;
    height: auto;
    display: block;
    margin: 32px auto;
  }
}
</style>
