<template>
  <div class="home-stories-from-heroes" v-if='blogPostsFeaturedFiltered && blogPostsFeaturedFiltered.length'>
    <shape placement="top" shape="angle" type="top" color="white" classes="relative" />
    <div class="home-stories-section">
      <div class="col-10 offset-1 text-center">
        <h2>Stories from Our Heroes</h2>
        <p class="main-page-p">We are inspiring patients and physicians to achieve better respiratory health.</p>
      </div>
      <client-only>
        <carousel :items="1" loop dots
                  :margin="10"
                  :nav="false"
                  class="container">
          <div class='blog-post-featured-slide d-flex row-desktop-column-mobile'
               v-for="blogPostFeatured in blogPostsFeaturedFiltered"
               :key="blogPostFeatured.id">
            <div class="img-container">
              <img :src="imageUrlFor(blogPostFeatured)" alt="">
            </div>
            <div class="blog-post-featured-content">
              <div class="title-container">
                <span class="title">{{blogPostFeatured.title}}</span>
                <span class="subtitle"> | {{blogPostFeatured.subtitle}}</span>
              </div>
              <div class="excerpt">{{blogPostFeatured.excerpt}}</div>
              <div class="link-container">
                <a :href="linkUrlFor(blogPostFeatured)" class="blog-post-link" v-if='!!blogPostFeatured.blog_post'>Read More...</a>
              </div>
            </div>
          </div>
        </carousel>
      </client-only>
    </div>
  </div>
</template>

<script>
import Shape from '../ui-kit/shape.vue';
import HomeStoriesFromHeroes from "@/components/home/homeStoriesFromHeroes.vue";
import './homeStoriesFromHeros.scss'
import '../../components/home/homeStoriesFromHeros.scss';
import { imageToSize } from '~/util/imageUtil'

export default {
  components: {
    Shape,
    HomeStoriesFromHeroes,
    'carousel': typeof window !==  'undefined' ? () => import('vue-owl-carousel') : undefined,
  },
  props: ['blogPostsFeatured'],
  computed: {
    blogPostsFeaturedFiltered() {
      return this.blogPostsFeatured ? this.blogPostsFeatured.filter(bpf => bpf.type === 'stories_from_our_heroes') : [];
    }
  },
  methods: {
    imageUrlFor(blogPostFeatured) {
      return imageToSize(blogPostFeatured.image, 'medium');
    },
    linkUrlFor(blogPostFeatured) {
      return blogPostFeatured.blog_post ? `/blog/${blogPostFeatured.blog_post.slug}` : '';
    }
  },
}
</script>
