<template>
  <div class="home-embracing-complexity mt-10 mb-10">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 col-12">
          <img src="~/assets/img/home/home-embracing-complexity.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-md-6 col-12 mb-0">
          <h2>
            NuvoAir embraces the complexity of cardiopulmonary conditions
          </h2>
          <p class="main-page-p">
            NuvoAir’s virtual-first care model helps prevent exacerbations associated with COPD and CHF by integrating a virtual clinic, personalized care, connected monitoring devices, and an omnichannel engagement engine to break the cycle of disconnection and failure.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
  .home-embracing-complexity {
    img {
      max-width: 500px;
      height: auto;
    }

  }
  @media (max-width: 768px) {
    .home-embracing-complexity {
      img {
        max-width: 100%;
        margin-bottom: 30px;
      }
    }
  }
</style>
