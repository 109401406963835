<template>
  <div class="home-managing-patients mt-10">
    <div class="container">
      <div class="row justify-content-center mb-10">
        <div class="col-12 col-md-8 text-center">
          <h2 class="mb-5">Managing patients with COPD and CHF is complex and challenging</h2>
          <p class="main-page-p text-gray">
            Cardiopulmonary patients, many of whom are struggling with multiple comorbidities, are often isolated, disengaged, and ignored by the healthcare system. Worse yet, point solutions have failed to deliver meaningful outcomes.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <div class="managing-patients-card">
            <img class="mb-5" src="~/assets/img/home/managing-patients-1.png" alt="">
            <p>
              One in two seniors has heart or lung complications
            </p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="managing-patients-card">
            <img class="mb-5" src="~/assets/img/home/managing-patients-2.png" alt="">
            <p>
              Exacerbations drive 60% of hospital admissions
            </p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="managing-patients-card">
            <img class="mb-5" src="~/assets/img/home/managing-patients-3.png" alt="">
            <p>
              70% of COPD admissions are associated with comorbidities
            </p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="managing-patients-card">
            <img class="mb-5" src="~/assets/img/home/managing-patients-4.png" alt="">
            <p>
              Wait times are 4 to 16 weeks to see a cardiologist or pulmonologist
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
  .managing-patients-card {
    display: block;
    margin: 0 auto;
    img {
      max-height: 65px;
      width: auto;
      display: block;
      margin: auto;
    }
    p {
      font-weight: 300;
      font-size: 18px;
      color: #506690;
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    .managing-patients-card {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }
</style>
