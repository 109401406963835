<template>
  <div class="home-proven-results pt-7 pb-7">
    <div class="container">
      <div class="row">
        <div class="col-10 offset-1 text-center">
          <h2>Proven Results</h2>
          <p class="main-page-p">
            Our virtual-first care solution effectively engages and manages people with COPD and comorbidities.
          </p>
        </div>
      </div>
      <div class="row proven-results-cards">
        <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-12">
          <div class="row m-0">
            <div class="col-12 col-md-3">
              <img class="home-proven-results-img" src="~/assets/img/home/home-proven-results-us-1.png" alt="" />
            </div>
            <div class="col-12 col-md-3">
              <img class="home-proven-results-img" src="~/assets/img/home/home-proven-results-us-2.png" alt="" />
            </div>
            <div class="col-12 col-md-3">
              <img class="home-proven-results-img" src="~/assets/img/home/home-proven-results-us-3.png" alt="" />
            </div>
            <div class="col-12 col-md-3">
              <img class="home-proven-results-img" src="~/assets/img/home/home-proven-results-1.png" alt="" />
            </div>
          </div>
          <div class="card card-border border-primary shadow-light mt-5 responsive-border shadow-default">
            <div class="row g-0 flex-container-cards">
              <div class="col-md-3 col-12">
                <div class="home-proven-results-card mobile-background-color">
                  <img
                    class="home-proven-results-mobile-img"
                    src="~/assets/img/home/home-proven-results-us-1.png"
                    alt=""
                  />
                  <span class="proven-results-figure"> 80% </span>
                  <span class="proven-results-desc"> Decrease in hospitalizations </span>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="home-proven-results-card mobile-background-color">
                  <img
                    class="home-proven-results-mobile-img"
                    src="~/assets/img/home/home-proven-results-us-2.png"
                    alt=""
                  />
                  <span class="proven-results-figure"> 60% </span>
                  <span class="proven-results-desc"> Decrease in exacerbations </span>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="home-proven-results-card mobile-background-color">
                  <img
                    class="home-proven-results-mobile-img"
                    src="~/assets/img/home/home-proven-results-us-3.png"
                    alt=""
                  />
                  <span class="proven-results-figure"> 87% </span>
                  <span class="proven-results-desc"> Improved quality of life </span>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="home-proven-results-card no-border mobile-background-color">
                  <img
                    class="home-proven-results-mobile-img"
                    src="~/assets/img/home/home-proven-results-1.png"
                    alt=""
                  />
                  <span class="proven-results-figure"> NPS 60 </span>
                  <span class="proven-results-desc"> Net promoter score </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10 col-md-6">
          <p class="disclaimer">
            *Based on patient reported outcomes from a 2021-2022 <a href="https://www.nuvoair.com/whitepapers/initial-data-suggest-nuvoair-virtual-first-clinical-service-for-copd-increases-patient-engagement-and-reduces-hospitalizations">pilot</a> with rural patients at a primary care clinic in Kentucky and a pulmonary specialty clinic in Indiana.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss">
.home-proven-results {

}

.home-proven-results-img {
  max-height: 100px;
  width: auto;
  display: block;
  margin: auto;
}
.home-proven-results-mobile-img {
  display: none;
}
.proven-results-cards {
  margin: 64px 0;
}
.proven-results-figure {
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 149.8%;
  letter-spacing: -0.01em;
  color: #161c2d;
}
.proven-results-desc {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 177.04%;
  text-align: center;
  color: #506690;
}
.home-proven-results-card {
  padding: 40px 0;
  border-right: 1px solid #e8f3ff;
  box-sizing: content-box;
}

@media (max-width: 767px) {
  .home-proven-results {
    padding: 32px 0;
  }
  .home-proven-results-img {
    display: none;
  }
  .home-proven-results-mobile-img {
    max-height: 128px;
    width: auto;
    display: block;
    margin: 0 auto 16px;
  }
}

@media (max-width: 411px){
  .mobile-background-color{
   background: #FFFFFF;
  box-shadow: 0px 24px 64px rgba(22, 27, 45, 0.1);
  border-radius: 5px;
  }
  .mobile-results-card-first img{
     margin: 0 auto;
  }
  .card-title{
    margin-right: 24px;
  }
  .responsive-border{
    border-top-color: transparent!important;
  }
  .flex-container-cards{
    display: flex;
    flex-direction: column;
    row-gap: 31px;
  }
  .shadow-default{
    box-shadow: none!important;
  }
}
</style>
