<template>
  <form @submit.prevent="organizationSignupForm_submitHandler">
    <div class="form-group">
      <label for="organizationFirstName">First Name</label>
      <input
        id="organizationFirstName"
        type="text"
        required
        class="form-control"
        placeholder="Enter first name"
        @input="validate()"
      />
    </div>
    <div class="form-group">
      <label for="organizationLastName">Last Name</label>
      <input
        id="organizationLastName"
        type="text"
        required
        class="form-control"
        placeholder="Enter last name"
        @input="validate()"
      />
    </div>
    <div class="form-group">
      <label for="organizationEmail">Email address</label>
      <input
        id="organizationEmail"
        type="email"
        required
        class="form-control"
        aria-describedby="emailHelp"
        placeholder="Enter email"
        @input="validate()"
      />
      <small id="emailHelp" class="form-text text-muted"> We'll never share your email with anyone else. </small>
    </div>
    <div class="form-group">
      <label for="organizationName">Organization</label>
      <input
        id="organizationName"
        type="text"
        required
        class="form-control"
        placeholder="Organization name"
        @input="validate()"
      />
    </div>
    <div class="form-group">
      <label for="organizationMessage">How can we help you? (optional)</label>
      <textarea id="organizationMessage" class="form-control" placeholder="Message"/>
    </div>
    <div class="form-check-inline">
      <input
        id="organizationConsent"
        type="checkbox"
        checked
        required
        class="form-check-input"
        @input="validate()"
      />
      <label for="organizationConsent" class="form-check-label" style="width: calc(100% - 24px); margin-bottom: 20px;">I agree to be contacted by NuvoAir, Inc. to learn more about NuvoAir’s services and solutions</label>
    </div>

    <button type="submit" :disabled="submitting || !valid" :class="submitButtonClassInternal">Submit</button>
    <div v-if="submitted">Request sent!</div>
  </form>
</template>
<script>
export default {
  props: [
    'category', // This is the enumeration on Organization Signups model on the backend.
    // Either 'clinical_trials' or 'health_plans'
    'submitButtonClass',
  ],
  data() {
    return {
      valid: false,
      submitting: false,
      submitted: false,
    };
  },
  computed: {
    submitButtonClassInternal() {
      return 'btn btn-ct btn-pill ' + this.submitButtonClass;
    },
  },
  methods: {
    resetForm() {
      document.getElementById('organizationEmail').value =
        document.getElementById('organizationFirstName').value =
        document.getElementById('organizationLastName').value =
        document.getElementById('organizationName').value =
        document.getElementById('organizationMessage').value =
          '';
    },
    validate() {
      this.valid =
        document.getElementById('organizationEmail').value &&
        document.getElementById('organizationFirstName').value &&
        document.getElementById('organizationLastName').value &&
        document.getElementById('organizationName').value &&
        document.getElementById('organizationConsent').checked
    },
    organizationSignupForm_submitHandler() {
      this.submitting = true;

      // See: backend/api/signup/controllers/signups.js for impl
      const body = {
        first_name: document.getElementById('organizationFirstName').value,
        last_name: document.getElementById('organizationLastName').value,
        email: document.getElementById('organizationEmail').value,
        organization_name: document.getElementById('organizationName').value,
        message: document.getElementById('organizationMessage').value,
        consent: document.getElementById('organizationConsent').checked,
        category: this.category,
      };

      fetch(this.$config.strapiBaseUrl + '/signup/organization', {
        method: 'post',
        body: JSON.stringify(body),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((_) => {
        this.submitting = false;
        this.submitted = true;
        this.resetForm();
      });
    },
  },
};
</script>
