<template>
  <div class="home-availability-map mb-10">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1 col-12">
          <h2 class="mb-0 mt-9 text-center">{{ title }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 offset-lg-1 col-12">
          <div class="map-wrapper">
            <img :src="imgUrl" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [ 'title', 'imgUrl' ],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>

</style>
