<template>
  <div class="awards-and-recognition">
    <div
      v-if="awards.length"
      class="container"
    >
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            Awards & Recognition
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 offset-lg-1 col-md-12 mb-5">
          <div class="awards-container">
            <div
              v-for="award in awards"
              :key="award.id"
              class="award-item"
            >
              <img
                :src="imageToSize(award.logo, 'small')"
                :alt="award.name"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './awardsAndRecognition.scss';
import { imageToSize } from '~/util/imageUtil';

export default {
  props: {
    awards: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageToSize,
    };
  },
};
</script>
